import React from "react";
import ModalVideo from 'react-modal-video'
import 'react-modal-video/scss/modal-video.scss';

const PlayVideo = (props)  => {
    console.log("props=>",props);

    return (
        <>
        {props.custumUrl == 0 ?
        <React.Fragment>
            <ModalVideo 
                channel='youtube' 
                autoplay 
                isOpen={props.isOpen} 
                videoId={props.videoId}
                autoplay={1}
                onClose={ e => props.isCloseFunction(false)}
            />
        </React.Fragment>
        :
        <React.Fragment>
            <ModalVideo 
                channel='custom'
                autoplay
                isOpen={props.isOpen} 
                url={props.custumUrl}
                onClose={ e => props.isCloseFunction(false)}
            />
        </React.Fragment>
        }
        </>
    )
};

export default PlayVideo;
