import React, { useState, useEffect } from "react"
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import ScrollAnimation from 'react-animate-on-scroll';
import './Properties.scss'
import axios from "axios";
import { Link } from "@StarberryUtils";

import { inViewOptions } from '../../utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import Loader from "../../loader";

const containerVariants = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.4,
            ease: [0.33, 1, 0.68, 1],
            duration: 0.4
        }
    }
}

const titleVariants = {
    hidden: {
        opacity: 0,
        y: 40
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            ease: [0.33, 1, 0.68, 1],
            duration: 1
        }
    }
}

const PropertiesList = (props) => {
    const [propItems, setPropItems] = useState([]);
    const [loader, showLoader] = React.useState(true);

    const path = ["/rent", "/landlords", "/landlords/landlords-guide"].includes(props.url) ? "-let" : props.url === "/buy" ? "-sales" : "/";
    useEffect(() => {
        // featured properties
        let url = process.env.GATSBY_STRAPI_SRC + "/stb-lists/item/featured-properties" + path;
        getItems(url)
    }, []);

    const getItems = async url => {
        try {
            const { data } = await axios.get(url, {
                headers: {
                Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
                }})
            if (data && data.length > 0) {
                // let filtered = data.filter(c => c.status !== "Sold");
                setPropItems(data);
                showLoader(false);
            }
        } catch (error) {
            console.error(error)
        }
    }
    const settings = {
        dots: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        arrows: true,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: false,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                },
            },
            {
                breakpoint: 377,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                },
            },
        ],
    }

    // const { data, loading, error } = GetPropertiesList(10);
    if(loader) {
        return <div className="d-flex justify-content-center w-100"><Loader/></div>
    }

    return (
        <InView {...inViewOptions}>
            {({ ref, inView }) => (
                <motion.section id="property" className="property property-list property-list-details"
                    ref={ref}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    variants={containerVariants}
                >
                    <Container>
                        <span class="sub-content">{props.sub_title ? props.sub_title : 'properties'}</span>
                        <ScrollAnimation animateIn='fadeInUp' animateOnce>
                            <motion.div variants={titleVariants}>
                                <h3>{props.title ? props.title : 'Find your next home'}</h3>
                            </motion.div>
                            {propItems.length > 0 && <Slider className={`slick-arrow ${propItems.length <= 2 ? "similar-single-properties" : ""}`} {...settings}>
                                {propItems.map((property, i) => {
                                    let processedImages = JSON.stringify({});
                                    if (property?.imagetransforms?.images_Transforms) {
                                        processedImages = property.imagetransforms.images_Transforms;
                                    }
                                    return (
                                        <div className="slider-wrap" key={i}>
                                            <Link to={`/property-${property.search_type === "sales" ? "for-sale" : "to-rent"}/${property.slug}-${property._id}`} className="w-100">
                                                <div class="slider w-100 position-relative">
                                                    <div className="home-img w-100">
                                                        {/* <div class="slider-bg-img w-100" style={{ backgroundImage: `url(${property.images[0].srcUrl.replace("http", "https")})`}}> */}
                                                        <div class="slider-bg-img w-100">
                                                            {property.images.length > 0 &&
                                                                <ImageTransform
                                                                    imagename="property.images.featured"
                                                                    attr={{ alt: '', class:'' }}
                                                                    imagesources={property.images[0].url}
                                                                    renderer="srcSet"
                                                                    imagetransformresult={processedImages}
                                                                    id={property._id}
                                                                />
                                                            }                          
                                                            {/* <img src={property.images[0]?.srcUrl.replace("http", "https")} alt="img" /> */}
                                                        </div>
                                                        <div className="home-content">
                                                            <h4>{property.display_address}</h4>
                                                            <span className="price">{property.price_qualifier} £{property.price.toLocaleString()}</span>
                                                        </div>
                                                    </div>
                                                    <button className="prop-card-btn">{property.status}</button>
                                                </div>
                                            </Link>
                                        </div>  
                                    )}   
                                )}
                                {window.innerWidth > 1024 && <div className="slider-wrap"></div>}
                            </Slider>}
                        </ScrollAnimation>
                    </Container>
                </motion.section>
            )}
        </InView>
    )
};

export default PropertiesList;