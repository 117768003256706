import React, { useState, useEffect } from "react"
import PlayVideo from "../../Play/PlayVideo";
import './Reviews.scss'
import Slider from "react-slick";
import ScrollAnimation from 'react-animate-on-scroll';
import { Container } from "react-bootstrap";
import moment from "moment/src/moment";
import axios from "axios";
import ReactReadMoreReadLess from "react-read-more-read-less";
import { TeamMemberList } from "../../../queries/common-use-query";
import teamImg from "./bovingdons_review.jpeg";

import { inViewOptions } from '../../utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";

const containerVariants = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.4,
            ease: [0.33, 1, 0.68, 1],
            duration: 0.4
        }
    }
}

const titleVariants = {
    hidden: {
        opacity: 0,
        y: 40
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            ease: [0.33, 1, 0.68, 1],
            duration: 1
        }
    }
}

const Reviews = (props) => {
    const [isPlay, setPlay] = useState(false);
    const [reviewList, setCustomerReview] = useState([]);
    const [allReviewList, setReviews] = useState([]);

    const wordToNumber = { 'ONE': 1, 'TWO': 2, 'THREE': 3, 'FOUR': 4, 'FIVE': 5 };

    let intialNumberOfReview = 10;

    const settings = {
        dots: false,
        arrow: true,
        adaptiveHeight: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    const strapiconfig = {
        headers: {
            Authorization:
                `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
        },
    }
    const getitems = async url => {
        try {
            const { data } = await axios.get(url,strapiconfig)// could be from env files 
            const reviews = data.filter(rev => rev.comment != null);
            setReviews(reviews);
            setCustomerReview(reviews.slice(0, intialNumberOfReview));
        } catch (error) {
            // cache it if fail/error;
            console.error(error)
        }
    }

    const starRating = (rating) => {
        var star = [];
        for (let i = 0; i < rating; i++) {
            star.push(<i className="star-icon"></i>);
        }
        return star;
    }
    
    useEffect(() => {
        let url = `${process.env.GATSBY_STRAPI_SRC}/stb-google-reviews/google-review-reviews`
        getitems(url);
    }, []);

    const { data, loading, error } = TeamMemberList();

    let images = [teamImg]; //[];

    if(data && data.teams) {
        data.teams.forEach(team => {
            images.push(team)
        });
    }
    return (
        <section className="reviews">
            <InView {...inViewOptions}>
                {({ ref, inView }) => (
                    <motion.section id="reviews" className="property property-list"
                        ref={ref}
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                        variants={containerVariants}
                    >
                        <Container>
                            <span className="sub-content reviews-sub-content">reviews</span>
                            
                            <motion.div variants={titleVariants}>
                                <h2>What our customers say</h2>
                            </motion.div>

                            <Slider className="slick-arrow" {...settings}>
                                {(reviewList && data && images.length > 2) && reviewList.map((review, i) => {
                                    let processedImages = JSON.stringify({});
                                    if (1 !== 0 && images[i % images.length]?.imagetransforms?.images_Transforms) {
                                        processedImages = images[i % images.length].imagetransforms.images_Transforms;
                                    }
                                    
                                    return (
                                        <div key={i}>
                                            <div className="slider">
                                                <ScrollAnimation animateIn='fadeInLeft' animateOnce>
                                                    <div className={`reviews-img ${i % images.length === 0?'singe-img':''}`}>
                                                        {(images.length > 2) &&
                                                            <>
                                                                {/* {console.log('length',images[i % images.length].Tile_Image.alternativeText)} */}
                                                                {i % images.length === 0 && <img src={teamImg} alt="" />}
                                                                {/* {i !== 0 && */}
                                                                {i % images.length !== 0 && 
                                                                    <ImageTransform
                                                                        imagename="teams.Tile_Image.tile"
                                                                        attr={{ alt: images[i % images.length].Tile_Image.alternativeText, class: 'card-img' }}
                                                                        imagesources={images[i % images.length].Tile_Image.url}
                                                                        renderer="srcSet"
                                                                        imagetransformresult={processedImages}
                                                                        id={images[i % images.length]._id}
                                                                    />
                                                                }
                                                            </>  
                                                    }
                                                    </div>
                                                </ScrollAnimation>
                                                <ScrollAnimation animateIn='fadeInRight' animateOnce>
                                                    <div className="reviews-content">
                                                        <a href="#" className="star">
                                                            {starRating(wordToNumber[review.starRating])}
                                                        </a>
                                                        <blockquote>
                                                            <ReactReadMoreReadLess
                                                                charLimit={300}
                                                                readMoreText={"Read more"}
                                                                readLessText={"Read less"}
                                                                readMoreClassName="read-more-less"
                                                                readLessClassName="read-more-less"
                                                            >
                                                                {review.comment}
                                                            </ReactReadMoreReadLess>
                                                        </blockquote>
                                                        <span className="customer-name">{review.reviewer?.displayName}</span>
                                                        <span className="time">{moment(review.updateTime).startOf("day").fromNow()}</span>
                                                    </div>
                                                </ScrollAnimation>
                                            </div>
                                        </div>
                                    )}   
                                )}
                            </Slider>
                        </Container>

                    </motion.section>
                )}
            </InView>

            <PlayVideo
                isOpen={isPlay}
                isCloseFunction={setPlay}
                videoId="V8ksSGxo6no"
                isAutoPlay={1}
            />
        </section>
    )
};
export default Reviews
